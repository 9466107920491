import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../utils/contexts/UserContext";
import { getConfirm } from "../../../services/auth/registrationService";
import bilAPI from "../../../services/api";
import useAuth from "../../../utils/hooks/useAuth";
import SimpleLoader from "../../../components/Loader/SimpleLoader";
import TokenService from "../../../services/token.service";

export default function Confirm() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(null);
  const { token } = useParams();
  const { setUserContext } = useAuth();

  const { user } = useContext(UserContext);

  useEffect(() => {
    getConfirm({ token })
      .then((res) => {
        setIsSuccess(true);
        setTimeout(() => {
          TokenService.updateLocalAccessToken(res.access);
          bilAPI.defaults.headers["Authorization"] = "Bearer " + res.access;
          TokenService.updateLocalRefreshToken(res.refresh);
          setUserContext(res.access);
        }, 3000);
      })
      ?.catch(() => setIsSuccess(false))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.uuid) {
      setIsLoading(false);
      if (user?.roles?.some((role) => role?.code === "ADMIN")) {
        navigate({
          pathname: `/admin/exams`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === "STD")) {
        navigate({
          pathname: `/student/profile`,
          state: { user: user },
        });
      }
    }

    return () => {};
  }, [user, navigate]);

  return (
    <div className="flex flex-col items-center justify-center mt-32">
      {isLoading && isSuccess != null ? (
        <SimpleLoader className="h-7" />
      ) : isSuccess ? (
        <div className="flex flex-col rounded-2xl border bg-green-200 p-8 text-4xl md:text-6xl">
          <span>{t("signup.verification_success")}</span>
          <span>{t("signup.you_will_redirected_soon")}</span>
        </div>
      ) : (
        <div className="rounded-2xl border bg-red-200 p-8 text-4xl md:text-6xl">
          {t("signup.invalid_or_expired_token")}
        </div>
      )}
    </div>
  );
}
