import React, { useState } from "react";
import AddText from "./BREAD/AddText";
import EditText from "./BREAD/EditText";
// import { EXAMTYPES } from "../../../../utils/constants/constants";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/forms/Buttons/Button";
// import EditAccess from "./BREAD/EditAccess";
import Edit from "./BREAD/Edit";
import Delete from "./BREAD/Delete";
import { EyeIcon } from "@heroicons/react/24/outline";

const ExaminationCard = ({ exam, pickedExam, setPickedExam, getData }) => {
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  let open = pickedExam?.uuid === exam?.uuid;
  return (
    <details
      key={exam?.uuid}
      onToggle={(e) => {
        if (e?.target?.open) {
          setPickedExam(exam);
        } else if (!e?.target?.open && open) {
          setPickedExam();
        }
      }}
      open={open}
      className="flex flex-col list-none items-start border shadow"
    >
      <summary className="w-full flex cursor-pointer list-none flex-row items-center bg-white">
        <p className=" flex flex-1 flex-row items-center justify-start gap-1 p-4 text-left ">
          <span>Название: </span>
          <span>{exam?.name}</span>
        </p>
        {/* <p className=" flex flex-1 flex-row items-center justify-start gap-1 p-4 text-left ">
          <span>Тип: </span>
          <span>{EXAMTYPES[exam?.type]}</span>
        </p> */}

        <p className=" flex flex-1 flex-row items-center justify-start gap-1 p-4 text-left ">
          <span>Длительность: </span>
          <span>{exam?.duration}</span>
        </p>
        <p className=" flex flex-1 flex-row items-center justify-start gap-1 p-4 text-left ">
          <span>Вариант: </span>
          <span>{exam?.variant}</span>
        </p>
        <div
          className="mr-4 flex flex-row gap-2"
          onClick={() => setPickedExam(exam)}
        >
          {/* <EditAccess exam={exam} getData={getData} /> */}
          <div className="flex flex-row">
            {!isDeleteMode && <Edit exam={exam} getData={getData} />}
            <Delete
              exam={exam}
              getData={getData}
              isDeleteMode={isDeleteMode}
              setIsDeleteMode={setIsDeleteMode}
            />
          </div>
        </div>
      </summary>
      <div className="flex flex-row items-center justify-between border-t px-2">
        <div className="flex flex-row gap-2">
          <p className=" flex flex-row items-center justify-start gap-1 p-4 text-left ">
            <span>Количество вопросов: </span>
            <span className="font-bold">{exam?.questions_count}</span>
          </p>
          <p className=" flex flex-row items-center justify-start gap-1 p-4 text-left ">
            <span>Математика:</span>
            <span className="font-bold">{exam?.math_questions_count}</span>
          </p>
        </div>
        <Link to={`${exam?.uuid}/questions`}>
          <Button text="Посмотреть" Icon={EyeIcon} />
        </Link>
      </div>
      <div className="flex flex-row items-center justify-between border-t px-2">
        <div className="flex flex-col gap-4">
          <p className=" flex flex-row items-center justify-start gap-1 p-4 pb-0 text-left ">
            <span>Грамотность чтение #1 (KAZ):</span>
            <span className="font-bold">{exam?.kaz_1_questions_count}</span>
          </p>
          <div className=" flex flex-row items-center justify-start gap-1 p-4 pt-0 text-left ">
            {exam?.kaz_1_text ? (
              <EditText
                header="Текст по казахскому 1"
                text={exam?.kaz_1_text}
                getData={getData}
              />
            ) : (
              <AddText
                header="Текст по казахскому 1"
                type="0"
                exam={exam?.uuid}
                getData={getData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className=" flex flex-row items-center justify-start gap-1 p-4 pb-0 text-left ">
            <span>Грамотность чтение #2 (KAZ):</span>

            <span className="font-bold">{exam?.kaz_2_questions_count}</span>
          </p>
          <div className=" flex flex-row items-center justify-start gap-1 p-4 pt-0 text-left ">
            {exam?.kaz_2_text ? (
              <EditText
                header="Текст по казахскому 2"
                text={exam?.kaz_2_text}
                getData={getData}
              />
            ) : (
              <AddText
                header="Текст по казахскому 2"
                type="1"
                exam={exam?.uuid}
                getData={getData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className=" flex flex-row items-center justify-start gap-1 p-4 pb-0 text-left ">
            <span>Грамотность чтение #1 (RU):</span>
            <span className="font-bold">{exam?.rus_1_questions_count}</span>
          </p>
          <div className=" flex flex-row items-center justify-start gap-1 p-4 pt-0 text-left ">
            {exam?.rus_1_text ? (
              <EditText
                header="Текст по русскому 1"
                text={exam?.rus_1_text}
                getData={getData}
              />
            ) : (
              <AddText
                header="Текст по русскому 1"
                type="2"
                exam={exam?.uuid}
                getData={getData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className=" flex flex-row items-center justify-start gap-1 p-4 pb-0 text-left ">
            <span>Грамотность чтение #2 (RU):</span>
            <span>{exam?.rus_2_questions_count}</span>
          </p>
          <div className=" flex flex-row items-center justify-start gap-1 p-4 pt-0 text-left ">
            {exam?.rus_2_text ? (
              <EditText
                header="Текст по русскому 2"
                text={exam?.rus_2_text}
                getData={getData}
              />
            ) : (
              <AddText
                header="Текст по русскому 2"
                type="3"
                exam={exam?.uuid}
                getData={getData}
              />
            )}
          </div>
        </div>
      </div>
    </details>
  );
};

export default ExaminationCard;
